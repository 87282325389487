import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import useGetAmenities from "../hooks/useGetAmenities";
import useGetLocations from "../hooks/useGetLocations";
import useGetTiers from "../hooks/useGetTiers";
import useGetTierCount from "../hooks/useGetTierCount";
import { LocationData, StudioData } from "../api/platform/Queries";
import { AccountContext } from "./AccountProvider";
import useGetGeoFromBrowser from "../hooks/useGetGeoFromBrowser";
import { useConfig } from "../configuration/useConfig";
import useStudioCount from "../hooks/useStudioCount";
import getProgramCode from "../utility/getProgramCode";
import useGetTierThreeCount from "../hooks/useGetTierThreeCount";

export const LocationsContext = React.createContext({});

type State = {
  bounds: unknown;
  locationDetail: unknown;
  studioDetail: unknown;
  locationHover: unknown;
  studioHover: unknown;
  searchCenter: unknown;
  tiersList: unknown;
  tierNameList: unknown;
  amenitiesList: unknown;
  studiosList: unknown;
  programCodes: unknown;
  visibleLocations: unknown;
  visibleStudios: unknown;
  currentAmenities: unknown;
  currentStudio: unknown;
  currentTier: unknown;
  radius: unknown;
  womenOnlyFilter: unknown;
};

type Action = {
  type: string;
} & Partial<State>;

// TODO: Setup config for locations

const LocationsProvider = ({
  packageSelected,
  isStudio,
  isPackagesPage,
  children,
  isIndividualStudioPurchaseAllowed,
  isLuxuryModal,
  setOpenChooseLuxuryGymModal,
  setShowSuccessToast,
  isMutuallyWellEligible,
}: {
  packageSelected: string;
  isStudio: boolean;
  isPackagesPage: boolean;
  children: React.ReactNode;
  isIndividualStudioPurchaseAllowed: boolean;
  isLuxuryModal?: boolean;
  setOpenChooseLuxuryGymModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSuccessToast?: React.Dispatch<React.SetStateAction<boolean>>;
  isMutuallyWellEligible?: boolean;
}) => {
  const LocationsReducer = (
    state: State,
    {
      type,
      bounds,
      locationDetail,
      studioDetail,
      locationHover,
      studioHover,
      searchCenter,
      tiersList,
      tierNameList,
      amenitiesList,
      studiosList,
      programCodes,
      visibleLocations,
      visibleStudios,
      currentAmenities,
      currentStudio,
      currentTier,
      radius,
      womenOnlyFilter,
    }: Action
  ): State => {
    switch (type) {
      // Only set complex state in reducer i.e. Arrays & Objects.
      // Integers, Strings, and Booleans should use useState
      case "SET_BOUNDS":
        return { ...state, bounds };
      case "SET_LOCATION_DETAIL":
        return { ...state, locationDetail };
      case "SET_STUDIO_DETAIL":
        return { ...state, studioDetail };
      case "SET_LOCATION_HOVER":
        return { ...state, locationHover };
      case "SET_STUDIO_HOVER":
        return { ...state, studioHover };
      case "SET_SEARCH_CENTER":
        return { ...state, searchCenter };
      case "SET_VISIBLE_LOCATIONS":
        return { ...state, visibleLocations };
      case "SET_VISIBLE_STUDIOS":
        return { ...state, visibleStudios };
      case "SET_PROGRAM_CODES":
        return { ...state, programCodes };
      case "SET_CURRENT_TIER":
        return { ...state, currentTier };
      case "SET_CURRENT_AMENITIES":
        return { ...state, currentAmenities };
      case "SET_CURRENT_STUDIO":
        return { ...state, currentStudio };
      case "SET_RADIUS":
        return { ...state, radius };
      case "SET_WOMEN_ONLY_FILTER":
        return { ...state, womenOnlyFilter };
      case "SET_AMENITIES":
        return { ...state, amenitiesList };
      case "SET_STUDIOS":
        return { ...state, studiosList };
      case "SET_TIERS":
        return { ...state, tiersList };
      case "SET_TIER_NAMES":
        return { ...state, tierNameList };
      default: {
        throw new Error(`Unhandled action type: ${type}`);
      }
    }
  };
  const { ...rest } = useContext(AccountContext);

  const [
    {
      bounds,
      locationDetail,
      studioDetail,
      locationHover,
      studioHover,
      searchCenter,
      tiersList,
      tierNameList,
      amenitiesList,
      programCodes,
      visibleLocations,
      visibleStudios,
      currentAmenities,
      currentStudio,
      currentTier,
      radius,
      womenOnlyFilter,
    },
    dispatch,
  ] = useReducer(LocationsReducer, {
    // Default state
    bounds: {},
    locationDetail: {},
    studioDetail: {},
    locationHover: {},
    studioHover: {},
    searchCenter: null,
    visibleLocations: [],
    visibleStudios: [],
    tiersList: null,
    tierNameList: null,
    amenitiesList: null,
    studiosList: null,
    currentTier: packageSelected ? packageSelected : rest.locationsTier ?? "3",
    currentAmenities: [],
    currentStudio: "",
    radius: 20,
    programCodes: null,
    womenOnlyFilter: false,
  });
  const [lookupAddress, setLookupAddress] = useState("Current Location");
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasValidSearch, setHasValidSearch] = useState("initial");
  const [programCode, setProgramCode] = useState();
  const [noResults, setNoResults] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false);

  const { config } = useConfig();
  const program = getProgramCode(config["client"]);

  const {
    getLocations,
    getStudios,
    locations,
    distances,
    studios,
    setStudios,
    setLocations,
    error,
    geoCode,
  } = useGetLocations({
    tier: currentTier as string,
    amenities: currentAmenities as string[],
    studio: currentStudio as string,
    radius: radius as number,
    womenOnlyFilter: womenOnlyFilter as boolean,
    isLuxuryModal: isLuxuryModal as boolean,
    isMutuallyWellEligible: isMutuallyWellEligible as boolean,
  });

  const { getTierCount, tierCount } = useGetTierCount();
  const { getStudioCount, studioCount } = useStudioCount();
  const { getTierThreeCount, tierThreeCount } = useGetTierThreeCount();

  const { geo, getCurrentLocation, geoDisabled, geoError } =
    useGetGeoFromBrowser();

  const { tiers, tierNames } = useGetTiers(program);

  const amenities = useGetAmenities();

  useEffect(() => {
    if (tiers) {
      dispatch({ type: "SET_TIERS", tiersList: tiers });
      dispatch({ type: "SET_TIER_NAMES", tierNameList: tierNames });
      if (packageSelected !== undefined) {
        const tierIndex = tiers.indexOf(packageSelected);
        if (tierIndex >= 0) {
          dispatch({ type: "SET_CURRENT_TIER", currentTier: tiers[tierIndex] });
        } else {
          dispatch({ type: "SET_CURRENT_TIER", currentTier: "0" });
        }
      } else {
        dispatch({ type: "SET_CURRENT_TIER", currentTier: tiers[3] });
      }
    }
    // eslint-disable-next-line
  }, [packageSelected, tiers]);

  useEffect(() => {
    dispatch({ type: "SET_AMENITIES", amenitiesList: amenities });
  }, [amenities]);

  // Set search center from Ip Address
  useEffect(() => {
    dispatch({ type: "SET_SEARCH_CENTER", searchCenter: geo });
  }, [geo]);

  const setProgramCodes = (programCodes: unknown) => {
    dispatch({ type: "SET_PROGRAM_CODES", programCodes });
  };
  // Get locations when a new valid search is entered
  useEffect(() => {
    (async () => {
      if (isMapLoaded && hasValidSearch && currentTier !== "0") {
        try {
          if (lookupAddress === "Current Location") {
            await getLocations.run(geo.lat, geo.lng, isStudio);
          } else {
            await getLocations.runWithAddress(lookupAddress, isStudio);
          }
        } catch (err: unknown) {
          return;
        }
      }
    })();
    // eslint-disable-next-line
  }, [
    isMapLoaded,
    hasValidSearch,
    programCode,
    lookupAddress,
    radius,
    currentAmenities,
    currentTier,
    womenOnlyFilter,
    isStudio,
  ]);

  useEffect(() => {
    (async () => {
      if (isMapLoaded && hasValidSearch && isPackagesPage) {
        try {
          if (lookupAddress === "Current Location") {
            await getLocations.run(geo.lat, geo.lng, false);
            if (config["client"] === "hcsc")
              await getStudios.run(geo.lat, geo.lng);
          } else {
            await getLocations.runWithAddress(lookupAddress, false);
            if (config["client"] === "hcsc")
              await getStudios.runWithAddress(lookupAddress);
          }
        } catch (err: unknown) {
          return;
        }
      }
    })();
    // eslint-disable-next-line
  }, [
    isMapLoaded,
    hasValidSearch,
    programCode,
    lookupAddress,
    radius,
    currentAmenities,
    currentTier,
    womenOnlyFilter,
    isPackagesPage,
  ]);

  // Set Loading and No Results states once we have locations
  useEffect(() => {
    if (!(error && (locations || studios) && tiersList)) {
      setLoading(false);
      setNoResults(!(locations.length > 0 || studios.length > 0));
    }
  }, [locations, studios, error, tiersList]);

  useEffect(() => {
    dispatch({
      type: "SET_SEARCH_CENTER",
      searchCenter: { lat: geoCode.latitude, lng: geoCode.longitude },
    });
  }, [geoCode]);

  const setVisibleLocations = useCallback(
    (bounds) => {
      const getVisibleLocations = () => {
        // Set default locations to all of them
        let newLocations = locations.slice();
        // Use selected location
        if (Object.keys(locationDetail as Record<string, unknown>).length) {
          newLocations = [locationDetail as LocationData];
        }

        // This code was not working properly, needed to find out the reason for this code
        // if (newLocations.length > 0) {
        //   return newLocations.filter((location) => {
        //     bounds.methods.contains({
        //       lat: location.latitude,
        //       lng: location.longitude,
        //     });
        //   });
        // }

        let filteredLocations;
        if (isLuxuryModal) {
          filteredLocations = newLocations.filter((location) => {
            return location.isHomeLocationTier === true;
          });
        }
        return isLuxuryModal ? filteredLocations : newLocations;
      };
      dispatch({
        type: "SET_VISIBLE_LOCATIONS",
        visibleLocations: getVisibleLocations(),
        bounds,
      });
    },
    [locations, locationDetail]
  );

  const setVisibleStudios = useCallback(
    (bounds) => {
      const getVisibleStudios = () => {
        // Set default locations to all of them
        let newStudios = studios.slice();

        // Use selected location
        if (Object.keys(studioDetail as Record<string, unknown>).length) {
          newStudios = [studioDetail as StudioData];
        }
        // This code was not working properly, needed to find out the reason for this code
        // if (newStudios.length > 0) {
        //   return newStudios.filter((studio) => {
        //     bounds.methods.contains({
        //       lat: studio.contactInfoViewModel.latitude,
        //       lng: studio.contactInfoViewModel.longitude,
        //     });
        //   });
        // }
        return newStudios;
      };
      dispatch({
        type: "SET_VISIBLE_STUDIOS",
        visibleStudios: getVisibleStudios(),
        bounds,
      });
    },
    [studioDetail, studios]
  );
  const setNoLocations = () => {
    dispatch({
      type: "SET_VISIBLE_LOCATIONS",
      visibleLocations: [],
      // bounds,
    });
  };

  const setNoStudios = () => {
    dispatch({
      type: "SET_VISIBLE_STUDIOS",
      visibleStudios: [],
      // bounds,
    });
  };

  useEffect(() => {
    if (isMapLoaded && !isStudio) {
      setVisibleLocations(bounds);
    }
  }, [bounds, locations, isMapLoaded, setVisibleLocations]);

  useEffect(() => {
    if (isMapLoaded && isStudio && isIndividualStudioPurchaseAllowed) {
      setVisibleStudios(bounds);
    }
  }, [bounds, studios, isMapLoaded, setVisibleStudios]);

  useEffect(() => {
    if (isMapLoaded && isPackagesPage && isIndividualStudioPurchaseAllowed) {
      setVisibleStudios(bounds);
    }
  }, [bounds, studios, isMapLoaded, setVisibleStudios]);

  useEffect(() => {
    if (geoDisabled && isMapLoaded) {
      //searchModal displayed if users geolocation is disabled and map has finished loading.
      setSearchModalOpen(true);
    } else if (
      !geoDisabled &&
      isMapLoaded &&
      hasValidSearch === "initial" &&
      currentTier !== "0"
    ) {
      //If we have users geolocation, map is ready and this is the first render, go ahead and get results for their geolocation.
      (async () => {
        try {
          await getLocations.run(geo.lat, geo.lng, isStudio);
        } catch (err: unknown) {
          return;
        }
      })();
    }
    // eslint-disable-next-line
  }, [geoDisabled, isMapLoaded, geo]);

  useEffect(() => {
    if (isMapLoaded && currentTier !== "0") {
      (async () => {
        try {
          await getTierCount.run(packageSelected as string, program);
        } catch (err: unknown) {
          return;
        }
      })();
    }
    // eslint-disable-next-line
  }, [packageSelected]);

  useEffect(() => {
    if (isMapLoaded && currentTier !== "0") {
      (async () => {
        try {
          await getTierCount.run(currentTier as string, program);
        } catch (err: unknown) {
          return;
        }
      })();
    }
    // eslint-disable-next-line
  }, [currentTier, isMapLoaded]);
  useEffect(() => {
    (async () => {
      try {
        await getTierThreeCount.run(program);
      } catch (err: unknown) {
        return;
      }
    })();
  }, []);

  useEffect(() => {
    if (isMapLoaded && config["client"] === "hcsc") {
      (async () => {
        try {
          await getStudioCount.run();
        } catch (err: unknown) {
          return;
        }
      })();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMapLoaded && config["client"] === "hcsc") {
      (async () => {
        try {
          await getStudioCount.run();
        } catch (err: unknown) {
          return;
        }
      })();
    }
    // eslint-disable-next-line
  }, [isMapLoaded]);

  return (
    <LocationsContext.Provider
      value={{
        loading,
        setLoading,
        isMapLoaded,
        setIsMapLoaded,
        bounds,
        setBounds: (bounds: unknown) =>
          dispatch({ type: "SET_BOUNDS", bounds }),
        searchCenter,
        setSearchCenter: (searchCenter: unknown) =>
          dispatch({ type: "SET_SEARCH_CENTER", searchCenter }),
        hasValidSearch,
        setHasValidSearch,
        locations,
        studios,
        setStudios,
        distances,
        setLocations,
        visibleLocations,
        visibleStudios,
        setVisibleLocations,
        setVisibleStudios,
        setNoLocations,
        setNoStudios,
        locationDetail,
        studioDetail,
        setLocationDetail: (locationDetail: unknown) =>
          dispatch({ type: "SET_LOCATION_DETAIL", locationDetail }),
        setStudioDetail: (studioDetail: unknown) =>
          dispatch({ type: "SET_STUDIO_DETAIL", studioDetail }),
        locationHover,
        setLocationHover: (locationHover: unknown) =>
          dispatch({ type: "SET_LOCATION_HOVER", locationHover }),
        studioHover,
        setStudioHover: (studioHover: unknown) =>
          dispatch({ type: "SET_STUDIO_HOVER", studioHover }),
        clearLocations: (validSearch: unknown) => {
          setLocations([]);
          setHasValidSearch(validSearch ? (validSearch as string) : "initial");
          setNoResults(false);
          dispatch({ type: "SET_LOCATION_DETAIL", locationDetail: {} });
        },
        clearStudios: (validSearch: unknown) => {
          setStudios([]);
          setHasValidSearch(validSearch ? (validSearch as string) : "initial");
          setNoResults(false);
          dispatch({ type: "SET_STUDIO_DETAIL", studioDetail: {} });
        },
        tiersList,
        tierNameList,
        tierCount,
        studioCount,
        tierThreeCount,
        amenitiesList,
        programCode,
        setProgramCode,
        programCodes,
        setProgramCodes,
        noResults,
        setNoResults,
        getCurrentLocation,
        setLookupAddress,
        setTier: (tier: string) =>
          dispatch({ type: "SET_CURRENT_TIER", currentTier: tier }),
        setAmenities: (amenities: string[]) =>
          dispatch({
            type: "SET_CURRENT_AMENITIES",
            currentAmenities: amenities,
          }),
        setCurrentStudio: (studio: string) =>
          dispatch({
            type: "SET_CURRENT_STUDIO",
            currentStudio: studio,
          }),
        setRadius: (radius: number) =>
          dispatch({ type: "SET_RADIUS", radius: radius }),
        setWomenOnlyFilter: (womenOnlyFilter: boolean) =>
          dispatch({
            type: "SET_WOMEN_ONLY_FILTER",
            womenOnlyFilter: womenOnlyFilter,
          }),
        currentTier,
        currentAmenities,
        radius,
        womenOnlyFilter,
        searchModalOpen,
        setSearchModalOpen,
        geoError,
        account: rest,
        setOpenChooseLuxuryGymModal,
        setShowSuccessToast,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};

export default LocationsProvider;
